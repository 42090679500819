import { Box, Button, Collapse, FormControl, FormLabel, HStack, Heading, Input, Select, SimpleGrid, Stack, Text, useDisclosure } from "@chakra-ui/react";
import { PageProps, graphql } from "gatsby";
import React, { useEffect, useState } from "react";

import Layout from "../components/Base/Layout";
import { MobileFilter } from "../components/Shop/MobileFilter";
import Pagination from "../components/Pagination";
import { PopoverIcon } from "../components/Header/PopoverIcon";
import { SEO } from "../components/Base/Seo";
import { SingleProductCard } from "../components/Product/SingleProductCard";

const SingleCategoryPage: React.FC<CategoryProps> = ({ data }) => {

    const nodes = data?.allWpProduct?.nodes || [];

    const { isOpen, onToggle } = useDisclosure()

    const [currentPage, setCurrentPage] = useState(1);
    const [filtered, setFiltered] = useState([...nodes]);

    const productsPerPage = 12;

    // Price range states
    const [minPrice, setMinPrice] = useState(0);
    const [maxPrice, setMaxPrice] = useState(Math.max(...nodes.map(p => Number(p.price.replace(/[^0-9.-]+/g, '')))));
    const [selectedMinPrice, setSelectedMinPrice] = useState(minPrice);
    const [selectedMaxPrice, setSelectedMaxPrice] = useState(maxPrice);


    useEffect(() => {
        if (nodes.length) {
            const min = Math.min(...nodes.map(p => Number(p.price.replace(/[^0-9.-]+/g, ''))));
            const max = Math.max(...nodes.map(p => Number(p.price.replace(/[^0-9.-]+/g, ''))));
            setMinPrice(min);
            setMaxPrice(max);
            setSelectedMinPrice(min);
            setSelectedMaxPrice(max);
        }
    }, [nodes]);

    //* SORT
    const onSelectionChange = (e) => {
        const sortDirection = e.target.value;
        const sortedArray = [...nodes].sort((a, b) => {
            if (sortDirection === 'a-z') return a.name.localeCompare(b.name);
            if (sortDirection === 'z-a') return b.name.localeCompare(a.name);
            if (sortDirection === 'priceASC') return Number(a.price.replace(/[^0-9.-]+/g, '')) - Number(b.price.replace(/[^0-9.-]+/g, ''));
            if (sortDirection === 'priceDESC') return Number(b.price.replace(/[^0-9.-]+/g, '')) - Number(a.price.replace(/[^0-9.-]+/g, ''));
            return 0;
        });

        setFiltered(sortedArray); // Update the filtered state with the sorted array
        setCurrentPage(1); // Reset to first page when sorting changes
    };

    const currentProducts = () => {
        const start = (currentPage - 1) * productsPerPage;
        const end = start + productsPerPage;
        return filtered.slice(start, end);
    };


    const handleFilters = () => {
        let res = nodes;

        res = res.filter((d) => {
            const price = Number(d.price.replace(/[^0-9.-]+/g, ''));
            return price >= selectedMinPrice && price <= selectedMaxPrice;
        });

        setCurrentPage(1); // Reset to first page when filters change
        setFiltered(res);
    };

    const handlePriceChange = () => {
        handleFilters();
    };

    // Pagination
    const numberOfPages = Math.ceil(filtered.length / productsPerPage);

    const prevPageHandler = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
            window.scrollTo(0, 0);
        }
    };

    const nextPageHandler = () => {
        if (currentPage < numberOfPages) {
            setCurrentPage(currentPage + 1);
            window.scrollTo(0, 0);
        }
    };

    const goToPage = (number: number) => {
        setCurrentPage(number);
        window.scrollTo(0, 0);
    };

    return (
        <Layout>
            <HStack alignItems="flex-start">
                <Stack display={{ md: "flex", base: "none" }} height="100vh" minW="240px" position="sticky" top="0" pt="72px" px="8px" spacing="10" maxW="240px" boxShadow="0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)">

                    <Stack mt="0px !important">
                        <Box>
                            <Button onClick={onToggle} variant="ghost" size="lg" p="0px" w="100%" justifyContent="space-between" fontWeight="700" rightIcon={<PopoverIcon isOpen={isOpen} />} _hover={{ backgroundColor: "none" }}>
                                Price range
                            </Button>
                        </Box>
                        <Collapse in={isOpen} animateOpacity >
                            <HStack gap="8px">
                                <FormControl>
                                    <FormLabel fontSize='12px'>Min</FormLabel>
                                    <Input
                                        type="number"
                                        value={selectedMinPrice}
                                        onChange={(e) => setSelectedMinPrice(Number(e.target.value))}
                                        onBlur={handlePriceChange}
                                        placeholder={`Min ${minPrice}`}
                                    />
                                </FormControl>

                                <Text pt="18px">-</Text>
                                <FormControl>
                                    <FormLabel fontSize='12px'>Max</FormLabel>
                                    <Input
                                        type="number"
                                        value={selectedMaxPrice}
                                        onChange={(e) => setSelectedMaxPrice(Number(e.target.value))}
                                        onBlur={handlePriceChange}
                                        placeholder={`Max ${maxPrice}`}
                                    />
                                </FormControl>

                            </HStack>
                        </Collapse>
                    </Stack>
                </Stack>
                <Box id="shopTemplatePart" height="100%" py="72px" width="100%" marginInline="1rem !important">
                    <Stack spacing={{ base: '6', md: '4' }} direction={{ base: 'column', md: 'row' }} justify="space-between" align="flex-start" width="full" pb="28px">
                        <Stack direction={{ base: 'column', md: 'row' }} align="baseline">
                            <Heading size="md" fontSize="2xl">
                                {/* @ts-ignore     */}
                                {nodes[0]?.productCategories?.nodes[0].name || 'All Products'}
                            </Heading>

                            <Text color="gray.500"> ({filtered.length} products)</Text>
                        </Stack>

                        <MobileFilter
                            handleFilters={handleFilters}
                            minPrice={minPrice}
                            maxPrice={maxPrice}
                            selectedMinPrice={selectedMinPrice}
                            setSelectedMinPrice={setSelectedMinPrice}
                            selectedMaxPrice={selectedMaxPrice}
                            setSelectedMaxPrice={setSelectedMaxPrice}
                            isCategoryPage={true}
                        />
                        <HStack display={{ base: 'none', md: 'flex' }}>
                            <Text flexShrink={0} color="gray.500" fontSize="sm">
                                Sort by
                            </Text>
                            <Select size="sm" aria-label="Sort by" rounded="md" onChange={onSelectionChange}>
                                <option value="a-z">Name: A-Z</option>
                                <option value="z-a">Name: Z-A</option>
                                <option value="priceASC">Price: ASC</option>
                                <option value="priceDESC">Price: DESC</option>
                            </Select>
                        </HStack>
                    </Stack>
                    <SimpleGrid columns={{ base: 1, sm: 2, md: 3, lg: 4 }} columnGap={4} rowGap={{ base: '8', md: '10' }}>
                        {currentProducts().map((item) => (
                            <SingleProductCard key={item.databaseId} props={item} />
                        ))}
                    </SimpleGrid>
                    <Pagination
                        numberOfPages={numberOfPages}
                        currentPage={currentPage}
                        prevPageHandler={prevPageHandler}
                        nextPageHandler={nextPageHandler}
                        goToPage={goToPage}
                        baseUrl={`shop/category/${nodes[0]?.productCategories?.nodes[0].slug}`}
                    />
                </Box>
            </HStack>
        </Layout>
    )
}

export default SingleCategoryPage;

type CategoryProps = PageProps<SingleCategoryType>

type SingleCategoryType = {
    allWpProduct: {
        nodes: {
            databaseId: number
            slug: string
            name: string
            image: {
                sourceUrl: string
            }
            price: string
            regularPrice: string
            productTags: {
                nodes: []
            },
            productCategories: {
                nodes: []
            }
        }[]
    }
}

export const query = graphql`
   query singleCategory($slug: String!) {
        allWpProduct(
            filter: {productCategories: {nodes: {elemMatch: {slug: {eq: $slug}}}}}
            sort: {fields: name, order: ASC}
        ) {
            nodes {
            databaseId
            slug
            name
            image {
                sourceUrl
            }
            productTags {
                nodes {
                    name
                    slug
                }
            }
            productCategories {
                nodes {
                    name
                    slug
                }
            }
            ... on WpSimpleProduct {
                    id
                    name
                    price
                    regularPrice
                    salePrice
                }
            }
        }
    }
`

export const Head = () => (
    <SEO title="CDN Health" description="A Wholesale Medical company. Your online shop for Specialty Medical Supplies." />
)