import * as React from "react";
import * as styles from "./shipping-policy.module.css";

import { FunctionComponent } from "react";
import Layout from "../components/Base/Layout";
import { SEO } from "../components/Base/Seo";

const ShippingPolicy: FunctionComponent = () => {

  return (
    <Layout>
      <div className={styles.shippingPolicy}>

        <div className={styles.docTemplate}>
          <div className={styles.textColumn}>
            <div className={styles.title}>
              <b className={styles.display}>Shipping & Refund Policy</b>
            </div>

            <div className={styles.subtitleLarge}>
              <div className={styles.display1}>Shipping Cost:
              </div>
            </div>
            <div className={styles.textbody}>
              <div className={styles.p1}>
                <div className={styles.caption}>
                  Orders valued under $500 incur a flat $15 shipping fee, while orders over $500 are eligible for free shipping.
                </div>

              </div>
              <div className={styles.subtitleLarge}>
                <div className={styles.display1}>Shipping Destination:
                </div>
              </div>
              <div className={styles.textbody}>
                <div className={styles.p1}>
                  <div className={styles.caption}>
                    Ship worldwide, but there may be intermittent restrictions with certain countries due to political reasons.
                  </div>
                </div>
              </div>

              <div className={styles.subtitleLarge}>
                <div className={styles.display1}>Order Delivery Time:
                </div>
              </div>
              <div className={styles.textbody4}>
                <div className={styles.p1}>
                  <div className={styles.caption}>
                    We aim to deliver your order within 14 days. Delays may occur due to:
                  </div>

                </div>
                <ul className={styles.callPhoneNumberOrAlertU}>
                  <li className={styles.callPhoneNumber}>
                    Incomplete order forms or missing release forms and prescriptions.
                  </li>
                  <li className={styles.callPhoneNumber}>
                    Illegible order forms.
                  </li>
                  <li className={styles.callPhoneNumber}>
                    Inaccurate or incomplete credit card information. Ensure all fields in the online form are completed, including credit card number and expiration date.
                  </li>
                  <li className={styles.callPhoneNumber}>
                    If mailing forms, make sure they are signed.
                  </li>
                  <li className={styles.callPhoneNumber}>
                    Some credit card companies may reject foreign transactions or purchases from countries you haven't recently visited. Contact your credit card provider beforehand to remove any international transaction blocks.
                  </li>
                  <li className={styles.callPhoneNumber}>
                    Ensure sufficient funds are available in your card to process the payment.
                  </li>
                  <li className={styles.callPhoneNumber}>
                    Payments via certified check or US money order may take longer to process as they need additional time for financial clearance.
                  </li>
                  <li className={styles.callPhoneNumber}>
                    Unforeseen delays can occur with international shipping, especially during peak holiday periods or extreme weather conditions. Order early to maintain a sufficient supply.
                  </li>

                </ul>
              </div>

              <div className={styles.subtitle2}>
                <div className={styles.display4}>What happens if something goes wrong with my order?:
                </div>
              </div>
              <div className={styles.textbody6}>
                <div className={styles.p1}>
                  <div className={styles.caption}>
                    <p className={styles.orderAmount}>
                      If your order does not reach you within 28 days from the confirmation of the sent order, we will resend it at no additional cost. If both orders eventually arrive, consider the duplicate as a complimentary gift from us.
                    </p>
                  </div>
                </div>
              </div>
              <div className={styles.textbody}>
                <div className={styles.p1}>
                  <div className={styles.caption}>
                    Example:
                  </div>
                </div>
                <div className={styles.p20}>
                  <div className={styles.caption}>
                    <ul className={styles.callPhoneNumberOrAlertU}>
                      <li className={styles.callPhoneNumber}>
                        Your order is placed and paid for.
                      </li>
                      <li className={styles.callPhoneNumber}>
                        If after 28 business days from receiving the confirmation email the order has not been delivered, we will initiate a re-shipment at our expense.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className={styles.textbody}>
                <div className={styles.p1}>
                  <div className={styles.caption}>
                    Exceptions to this policy include:
                  </div>
                </div>
                <div className={styles.p20}>
                  <div className={styles.caption}>
                    <ul className={styles.callPhoneNumberOrAlertU}>
                      <li className={styles.callPhoneNumber}>
                        Providing incorrect shipping information at the time of order.
                      </li>
                      <li className={styles.callPhoneNumber}>
                        Refusal of delivery.
                      </li>
                      <li className={styles.callPhoneNumber}>
                        Package being returned to the postal outlet for pickup.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className={styles.subtitle2}>
                <div className={styles.display4}>What If I Need To cancel my Order?
                </div>
              </div>
              <div className={styles.textbody4}>
                <div className={styles.p1}>
                  <div className={styles.caption}>
                    If you need to cancel an order, you can do so without incurring any charges or penalties until your order is prepared. It is recommended to cancel your order as soon as possible to prevent it from being shipped out. Once the order has already been shipped, cancellation or refund requests will not be possible.
                  </div>
                </div>
              </div>

              <div className={styles.subtitle2}>
                <div className={styles.display4}>Product Returns:
                </div>
              </div>
              <div className={styles.textbody4}>
                <div className={styles.p1}>
                  <div className={styles.caption}>
                    Product returns are not accepted. Contact customer service for assistance.
                  </div>
                </div>
              </div>

              <div className={styles.subtitle2}>
                <div className={styles.display4}>Refund Process:
                </div>
              </div>
              <div className={styles.textbody4}>
                <div className={styles.p1}>
                  <div className={styles.caption30}>
                    If by any chance you are dissatisfied with your order, kindly get in touch with us. Refunds will only be granted in the following situations: if there was misrepresentation of the product packaging at the time of ordering, if the package arrived damaged, or if the product is found to be ineffective during initial use.
                  </div>
                </div>
                <div className={styles.p1}>
                  <div className={styles.caption30}>
                    In any of these scenarios, please retain the products until you receive additional guidance from us. If a refund is deemed appropriate, you may opt for a replacement, a full refund, or receive store credit for the item.
                  </div>
                </div>
              </div>
              <div className={styles.subtitle2}>
                <div className={styles.display4}>Refund Eligibility:
                </div>
              </div>
              <div className={styles.textbody4}>
                <div className={styles.p1}>
                  <div className={styles.caption}>
                    Refunds are at the discretion of the company and are not guaranteed in all cases.
                  </div>
                </div>
              </div>
              <div className={styles.textbody4}>
                <div className={styles.p1}>
                  <div className={styles.caption}>
                    Contact customer service for more details on the refund policy.
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ShippingPolicy;

export const Head = () => (
  <SEO title="CDN Health | Shipping policy" description="Shipping policy" />
)
